import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  Radio,
  Form,
  Input,
  Typography,
  RadioChangeEvent,
  Select,
  Divider,
  Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import { Talent } from '../../../../helper/type';
import { Div } from '../../../../framework';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title, Link, Paragraph } = Typography;
const { Option } = Select;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const InfluencerPage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const tid = new URLSearchParams(search).get('tid');
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [name, setName] = useState('');
  const [areas, setAreas] = useState<string[]>([
    'Lifestyle',
    'Beauty',
    'Fashion',
    'Food',
    'Travel',
    'Finance',
    'Automobile',
    'Politic',
    'Art',
  ]);

  useEffect(() => {
    setStep(7);
  });

  useEffect(() => {
    if (talent?.kol && Object.keys((talent as Talent).kol).length) {
      form.setFieldsValue(talent.kol);
      setIsInfluencer(true);
    }
  }, [talent]);

  const onIsInfluencerChange = (event: RadioChangeEvent) => {
    setIsInfluencer(event.target.value);
  };

  const addArea = (e: React.MouseEvent) => {
    e.preventDefault();
    setAreas([...areas, name]);
    setName('');
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onFinish = async (values: any) => {
    const talentId = impersonator.impersonateId || authUser.username;
    const updatedValue = {
      kol: isInfluencer ? values : {},
    };

    await talentService.updateProfile(updatedValue, talentId);
    dispatch(setPartialTalentProfile(updatedValue));
    const location = {
      pathname: '/talent/profile/agent',
      search: generateSearchParamString({ edit, tid }),
      state,
    };

    history.push(location);
  };

  return (
    <Form
      style={{ width: '340px' }}
      layout='vertical'
      form={form}
      onFinish={onFinish}
    >
      <Title level={3}>KOL / KOC / Influencer</Title>
      <Div mb='xl'>
        <Radio.Group value={isInfluencer} onChange={onIsInfluencerChange}>
          <Paragraph>Are you KOL / KOC / influencer?</Paragraph>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Div>
      <Form.Item
        name='areas'
        label='Which areas are your focus?'
        rules={[
          {
            required: isInfluencer,
            type: 'array',
            min: isInfluencer ? 1 : 0,
            message: 'Please select at least an area of focus',
          },
        ]}
      >
        <Select
          mode='multiple'
          disabled={!isInfluencer}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space align='center' style={{ padding: '0 8px 4px' }}>
                <Input
                  placeholder='Please enter item'
                  value={name}
                  onChange={onNameChange}
                />
                <Link onClick={addArea} style={{ whiteSpace: 'nowrap' }}>
                  <PlusOutlined /> Add area of focus
                </Link>
              </Space>
            </>
          )}
        >
          {areas.map((area) => (
            <Option key={area} value={area}>
              {area}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update &amp; Next
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/experience',
                search: generateSearchParamString({ edit, tid }),
                state,
              };

              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default InfluencerPage;
